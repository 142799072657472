import { createApi } from "@reduxjs/toolkit/query/react";
import customFetchBaseQuery from "../../app/customFetchBaseQuery";

export interface Clinician {
  /* TODO: add data types for clinician .. */
  id: string;
  first_name: string;
  last_name: string;
  medical_centre: string;
  work_phone: string;
  personal_phone: string;
  work_email: string;
  personal_email: string;
  contact_preference: string;
  payment_preference: string;
  notes: string;
  street: string;
  city: string;
  post_code: string;
  state: string;
  consent_status: string;
  num_allocated_participants: string;
  last_allocate_date_time: string;
}

export function isClinicianType(obj: unknown): obj is Clinician {
  return typeof obj === "object" && obj !== null && "medical_centre" in obj;
}

export const clinicianApi = createApi({
  reducerPath: "clinicianApi",
  baseQuery: customFetchBaseQuery,
  tagTypes: ["Clinician"],
  endpoints: (builder) => ({
    getClinicians: builder.query<Clinician[], void>({
      query() {
        return {
          url: "clinicians",
          method: "GET",
        };
      },
      providesTags(result) {
        return result
          ? [
              ...result.map(({ id }) => ({
                type: "Clinician" as const,
                id: id,
              })),
              { type: "Clinician", id: "LIST" },
            ]
          : ["Clinician"];
      },
    }),
    getClinician: builder.query<Clinician, string | undefined>({
      query(id) {
        return {
          url: `clinicians/${id}`,
          method: "GET",
        };
      },
      providesTags(result, error, id) {
        return [{ type: "Clinician", id: id }];
      },
    }),
    updateClinician: builder.mutation<void, Pick<Clinician, "id"> & Clinician>({
      query({ id, ...clinician }) {
        return {
          url: `clinicians/${id}`,
          method: "PUT",
          credentials: "include",
          body: clinician,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: "Clinician", id: arg.id },
      ],
    }),
    addClinician: builder.mutation<Clinician, Clinician>({
      query(clinician) {
        return {
          url: "clinicians",
          method: "POST",
          credentials: "include",
          body: clinician,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: "Clinician", id: arg.id },
      ],
    }),
  }),
});

export const {
  useGetCliniciansQuery,
  useGetClinicianQuery,
  useUpdateClinicianMutation,
  useAddClinicianMutation,
} = clinicianApi;
