import { createApi } from "@reduxjs/toolkit/query/react";
import customFetchBaseQuery from "../../app/customFetchBaseQuery";

export interface Participant {
  record_id: string;
  mobile: string;
  register_date_time: string;
  randomised_participant: string;
  randomised_date_time: string;
  stop_message_date_time: string;
  allocated_navigator: string;
  project_cutoff_date_time: string;
  trial_discovery: string;
  trial_discovery_other: string;

  status: string;
  reminder_date: string;
  due_date: string;
  cutoff_date: string;
  has_lapsed: string;
  status_update_date_time: string;
  optional_data: string;
  notes_box: string;
  status_graph_bypass: string;

  hcv_test_reminder_date: string;
  daa_reminder_date: string;
  mid_treatment_checkin: string;
  svr_reminder_date: string;
  next_contact_date: string;
  is_cold: string;
  contact_type1: string;
  contact_datetime1: string;
  contact_type2: string;
  contact_datetime2: string;
  contact_type3: string;
  contact_datetime3: string;

  self_report_hep_c: string;
  on_current_hep_c_treatment: string;
  age_18_or_older: string;
  has_medicare_card: string;
  resident_in_australia: string;
  previously_enrolled: string;
  able_complete_follow_up: string;
  contraindication_treatment: string;
  able_to_provide_consent: string;
  confirm_eligible_date_time: string;

  has_read_pi_sheet: string;
  understands_procedures_risk: string;
  queries_answered: string;
  freely_agrees_to_participate: string;
  aware_of_project_withdrawal: string;
  consents_to_pi_use: string;
  navigator_confirm_consent: string;
  consent_date_time: string;
  consented_by: string;
  future_research_opt: string;
  consent_comments: string;

  first_name: string;
  last_name: string;
  date_of_birth: string;
  sex: string;
  preferred_mobile: string;
  payment_method: string;
  mailing_street: string;
  mailing_city: string;
  mailing_post_code: string;
  mailing_state: string;
  preferred_name: string;
  participant_email_address: string;

  did_request_prescriber: string;
  clinician_letter_sent: string;
  requested_prescriber_id: string;
  randomised_clinician: string;
  allocated_prescriber_id: string;
  clinician_payment_amount: string;

  hcv_test_results: string;
  hcv_test_date: string;
  hcv_test_comments: string;
  hcv_test_confirm_date_time: string;

  daa_meeting_date_time: string;
  daa_verified_date_time: string;
  daa_verified: string;
  daa_tmt_schedule: string;
  daa_tmt_start_date_time: string;
  daa_tmt_complete_date_time: string;
  daa_participant_name_verified: string;
  daa_clinician_name_verified: string;
  daa_tmt_dispensed_date: string;
  daa_tmt_name: string;
  daa_tmt_repeats: string;

  /* DAA Follow-up 
  Not represented
  daaf_live, daaf_sms_status,
  */
  daaf_sms_consented: string;
  daaf_days_tmt_missed: string;
  daaf_mt_incentive_received: string;
  daaf_mt_incentive_problems_text: string;
  daaf_any_problems: string;
  daaf_any_problems_text: string;
  daaf_eot_checkin_date_time: string;
  daaf_eot_checkin_meeting_date_time: string;

  svr_test_results: string;
  svr_test_date: string;
  svr_test_comments: string;
  svr_test_confirm_date_time: string;

  /* TODO: add data types for convo, consent, etc.. */
}

export const participantApi = createApi({
  reducerPath: "participantApi",
  baseQuery: customFetchBaseQuery,
  tagTypes: ["Participant"],
  endpoints: (builder) => ({
    getParticipants: builder.query<Participant[], void>({
      query() {
        return {
          url: "participants",
          method: "GET",
          credentials: "include",
        };
      },
      providesTags(result) {
        return result
          ? [
              ...result.map(({ record_id }) => ({
                type: "Participant" as const,
                id: record_id,
              })),
              { type: "Participant", id: "LIST" },
            ]
          : ["Participant"];
      },
    }),
    getParticipantsByNavigator: builder.query<
      Participant[],
      string | undefined
    >({
      query(nav_id) {
        return {
          url: `participants?allocated_navigator=${nav_id}`,
          method: "GET",
          credentials: "include",
        };
      },
      providesTags(result) {
        return result
          ? [
              ...result.map(({ record_id }) => ({
                type: "Participant" as const,
                id: record_id,
              })),
              { type: "Participant", id: "LIST" },
            ]
          : ["Participant"];
      },
    }),
    getParticipant: builder.query<Participant, string | undefined>({
      query(id) {
        return {
          url: `participants/${id}`,
          method: "GET",
          credentials: "include",
        };
      },
      providesTags(result, error, id) {
        return [{ type: "Participant", id: id }];
      },
    }),
    updateParticipant: builder.mutation<
      Participant,
      Pick<Participant, "record_id"> &
        Pick<Participant, "status_graph_bypass"> &
        Participant
    >({
      query({ record_id, status_graph_bypass, ...participant }) {
        return {
          url: `participants/${record_id}`,
          /* Set params only if status_graph_bypass value is true */
          params:
            status_graph_bypass === "true"
              ? {
                  status_graph_bypass,
                }
              : undefined,
          method: "PUT",
          credentials: "include",
          body: participant,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: "Participant", id: arg.record_id },
      ],
    }),
  }),
});

export const {
  useGetParticipantsQuery,
  useGetParticipantsByNavigatorQuery,
  useGetParticipantQuery,
  useUpdateParticipantMutation,
} = participantApi;
